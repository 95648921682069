.profilesu {
  @apply flex justify-center mx-auto h-screen overflow-hidden;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../assets/images/circlebg.png);
  background-size: cover;
  &_main {
    @apply flex mt-48 sm:mt-44 items-center flex-col w-10/12;
    &_profilesu {
      @apply opacity-75 text-center font-medium text-base sm:text-2xl;
      color: #b09dfc;
    }
    &_title {
      @apply text-white opacity-75 text-center font-medium text-xl sm:text-5xl;
    }
    &_subtitle {
      @apply opacity-75 text-center font-medium sm:mt-4 text-base sm:text-xl sm:w-5/12;
      color: #636363;
    }
    &_container {
      @apply flex gap-1 sm:gap-5 mt-2 sm:mt-12 flex-col items-center overflow-y-scroll overflow-x-hidden mb-20 pb-2 sm:pb-6 pr-2;

      &::-webkit-scrollbar {
        width: 0.5rem;
      }

      &::-webkit-scrollbar-track {
        background: #1d1e20;
      }

      &::-webkit-scrollbar-thumb {
        background: #2b2b2b;
        border-radius: 0.5rem;
      }

      width: 100%;
      &_inputcontainer {
        @apply flex flex-col gap-2 w-full;
      }
      &_title {
        @apply text-base sm:text-lg mt-1 sm:mt-0;
        color: #bbb;
      }
      &_input {
        @apply rounded-md px-4 py-2 sm:py-3 outline-none text-sm sm:text-base;
        border: 1px solid #2b2b2b;
        background: #111;
        color: #dadada;
      }
      &_socials {
        @apply flex flex-col w-full gap-4;
        &_row {
          @apply flex flex-col sm:flex-row gap-4;
          &_input {
            @apply w-full sm:w-1/2 px-3 py-1 sm:py-2 bg-inherit rounded-sm flex items-center;
            border: 1px solid
              var(--ddnight-content-border-border-grey-75, #2c2e31);
            color: var(--ddnight-content-text-greys-60, #7e7e7e);
            input {
              width: 150px;
              @apply bg-inherit outline-none pb-1;
              color: #eee;
            }
            #twitter{
              @apply w-full;
            }
            #linkedin {
              @apply w-full sm:w-24;
            }
          }
          &_discordinput {
            @apply px-3 w-full py-2 bg-inherit rounded-sm flex items-center;
            border: 1px solid
              var(--ddnight-content-border-border-grey-75, #2c2e31);
            color: var(--ddnight-content-text-greys-60, #7e7e7e);
            input {
              @apply bg-inherit outline-none pb-1 w-80;
              color: #eee;
            }
            #linkedin {
              @apply w-24;
            }
          }
        }
      }
      &_submit {
        @apply rounded-md p-2.5 w-64 text-center mt-4 sm:mt-14 cursor-pointer text-black;
        background: #f2f2f2;
        box-shadow: 0px 2px 1px 0px #fff inset,
          0px -2px 1px 0px rgba(10, 10, 10, 0.29) inset;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1239px) {
  .profilesu_main {
    width: 600px;
  }
}

@media (min-width: 1240px) and (max-width: 1559px) {
  .profilesu_main {
    width: 700px;
  }
}
@media (min-width: 1560px) {
  .profilesu_main {
    width: 750px;
  }
}
