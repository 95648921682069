.homepage {
  @apply flex justify-center mx-auto h-screen overflow-hidden;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../assets/images/homebg.png);
  background-size: cover;
  &_main {
    @apply flex justify-center items-center flex-col w-full;
    &_text {
      @apply text-white opacity-75 text-center font-medium sm:text-6xl text-3xl;
    }
    &_button {
      @apply rounded-xl sm:p-6 p-4 mt-8 text-white text-opacity-50 font-medium sm:text-3xl text-xl cursor-pointer;
      background: radial-gradient(
        130.26% 130.26% at 49.93% -29.48%,
        #272727 0%,
        #060a05 100%
      );
      box-shadow: 0px 5.08822px 0.63603px -3.39215px rgba(125, 125, 125, 0.85) inset,
        0px 0.93284px 0.42402px 0px #000 inset,
        0px -0.84804px 0.84804px 1.27206px rgba(0, 0, 0, 0.75) inset,
        0px 4.02817px 0.42402px -3.39215px rgba(56, 56, 56, 0.55);
    }
  }
}
