.userprofile {
  @apply flex flex-col mx-auto xl:h-screen;
  width: 90%;
  &_main {
    @apply flex flex-col xl:flex-row gap-8 mt-52 lg:mt-44 mb-8 h-full w-full overflow-hidden;
    &_left {
      @apply p-8 rounded-xl flex flex-col h-full w-full;
      border: 1px solid #141414;
      background: linear-gradient(180deg, #111 0%, #0c0c0c 100%);
      &_outer {
        @apply flex flex-col h-full w-full p-1 rounded-xl;
        border: 1px solid #1b1b1b;
        background: #060606;
        &_outer {
          @apply flex flex-col h-full p-1 rounded-xl;
          border: 1px solid #262626;
          width: 450px;

          background: linear-gradient(
            180deg,
            #0b0b0b 0%,
            rgba(11, 11, 11, 0.19) 100%
          );
        }
        @media (max-width: 1280px) {
          &_outer {
            width: 100%;
          }
        }
      }
      &_top {
        @apply flex gap-4;
      }
      &_profileimagecontainer {
        @apply xl:flex sm:h-40 sm:w-40 h-20 w-20 mx-auto sm:mx-0 rounded-full justify-center items-center font-black text-6xl;
        background-image: url("../../assets/images/profileimagebg.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #ae9afc;
      }
      &_namecontainer {
        @apply flex gap-3 mt-6 items-center;
        color: #dedede;

        &_name {
          @apply text-xl font-medium;
        }
        &_editbtn {
          @apply rounded-md px-2.5 py-2 cursor-pointer;
          border: 1px solid #575757;
          background: #151515;
        }
      }
      &_username {
        @apply text-sm mt-1;
        color: #737373;
      }
      &_socialcontainer {
        @apply flex flex-wrap gap-2 mt-4 text-xs md:text-sm xl:text-base;
        &_social {
          @apply px-2 py-1 gap-1 flex rounded-3xl items-center;
          border: 1px solid
            var(--ddnight-content-border-border-grey-100, #1d1e20);
          background: var(--ddnight-material-90, #131416);
          color: var(--ddnight-content-text-greys-60, #7e7e7e);
        }
      }
      &_infocontainer {
        @apply flex flex-col gap-1 mt-4 text-xs md:text-sm xl:text-base;
        &_title {
          color: #595959;
        }
        &_value {
          color: #dedede;
        }
        &_tags {
          @apply flex gap-2 flex-wrap;
          &_tag {
            @apply rounded-3xl px-2 py-1;
            color: #dadada;
            border: 1px solid #2f2f2f;
            opacity: 0.8;
            background: #212121;
          }
        }
      }
    }
    &_right {
      @apply p-4 md:p-6 xl:p-8 rounded-xl flex flex-col h-full w-full;
      border: 1px solid #141414;
      background: linear-gradient(180deg, #111 0%, #0c0c0c 100%);
      &_outer {
        @apply flex flex-col h-full p-1 rounded-xl w-full;
        border: 1px solid #1b1b1b;
        background: #060606;
        &_outer {
          @apply flex flex-col h-full p-1 rounded-xl;
          width: calc(100% - 482px);
          border: 1px solid #262626;
          background: linear-gradient(
            180deg,
            #0b0b0b 0%,
            rgba(11, 11, 11, 0.19) 100%
          );
        }
        @media (max-width: 1280px) {
          &_outer {
            width: 100%;
          }
        }
      }
      &_scrollcontainer {
        @apply flex flex-col overflow-y-auto overflow-x-hidden pr-2;
        &::-webkit-scrollbar {
        width: 0.5rem;
        }
        &::-webkit-scrollbar-track {
          background: #1d1e20;
        }

        &::-webkit-scrollbar-thumb {
          background: #2b2b2b;
          border-radius: 0.5rem;
        }
      }
      &_cards {
        @apply flex flex-col gap-4 mt-8;
        &_card {
          @apply p-4 gap-4 rounded-xl flex flex-col cursor-pointer;
          border: 1px solid #252525;
          background: #111;
          &_left {
            @apply flex gap-4;
            width: 80%;

            &_image {
              @apply h-12 w-12 sm:h-24 sm:w-24  xl:h-32 xl:w-32;
              img {
                @apply w-full h-full object-cover;
              }
            }
            &_details {
              @apply flex flex-col font-medium;
              width: calc(100% - 128px);
              &_top {
                @apply flex gap-2 h-fit text-sm;
                &_type {
                  @apply rounded-md p-2;
                  background-color: #252525;
                  color: #7df5d1;
                }
                &_amount {
                  @apply rounded-md p-2;
                  background-color: #252525;
                  color: #f7c487;
                }
              }
              &_name {
                @apply text-white font-semibold lg:mt-2;
              }
              &_by {
                @apply text-xs mt-0.5;
                color: #adadad;
              }
              &_desc {
                @apply hidden md:flex text-sm mt-3;
                color: #878787;
              }
            }
          }
          @media (max-width: 1280px) {
            &_left {
              width: 100%;
              &_details{
                              width: calc(100% - 48px);

              }
            }
          }
          &_right {
            @apply flex items-end flex-col;
            width: 20%;
            &_submission {
              @apply rounded-md w-fit h-fit p-2 text-sm xl:text-base xl:text-end;
              background: rgba(142, 212, 141, 0.12);
              color: #8ed48d;
            }
            &_tags {
              @apply gap-2 mt-4 mb-2 hidden lg:flex;
              &_tag {
                @apply rounded-3xl py-1 px-2;
                color: #9d9d9d;

                border: 1px solid #323232;
                background: #252525;
              }
            }
            &_date {
              @apply mt-2 xl:text-end text-xs md:text-sm xl:text-base;
              color: #787878;
            }
          }
          @media (max-width: 1280px) {
            &_right {
              width: 100%;
              align-items: flex-start;
            }
          }
        }
      }
      &_infocontainer {
        @apply flex flex-col gap-1 mt-4 pl-2;
        &_title {
          color: #595959;
        }
        &_value {
          color: #dedede;
        }
      }
      &_workcontainer {
        @apply pl-2;
        &_titlecontainer {
          @apply mt-6 flex justify-between items-center mb-3;
          &_title {
            @apply font-semibold;
            color: #595959;
          }
          &_add {
            @apply rounded-full w-8 h-8 pb-1 justify-center items-center text-4xl font-bold cursor-pointer;
            border: 1.303px solid #3a3a3a;
            background: #292929;
            color: #929292;
          }
        }
        &_cards {
          @apply flex gap-2 flex-col mt-2;
          &_card {
            @apply rounded-md p-4 w-full flex flex-col gap-2;
            border: 1px solid #242424;
            background: #0c0c0c;
            &_title {
              @apply text-lg;
              color: #b399ec;
            }
            &_details {
              @apply flex flex-col lg:flex-row gap-4 w-full lg:items-center;
              &_desc {
                @apply text-sm xl:w-8/12;
                color: #c2c2c2;
              }
              &_tags {
                @apply flex gap-2 lg:justify-end flex-wrap lg:w-4/12;
                &_tag {
                  @apply rounded-3xl px-2 py-1 text-sm;
                  color: #dadada;
                  border: 1px solid #2f2f2f;
                  opacity: 0.8;
                  background: #212121;
                }
              }
            }
          }
        }
      }
      &_expcontainer {
        @apply pl-2;
        &_titlecontainer {
          @apply mt-6 flex justify-between items-center mb-3;
          &_title {
            @apply font-semibold;
            color: #595959;
          }
          &_add {
            @apply rounded-full w-8 h-8 pb-1 justify-center items-center text-4xl font-bold cursor-pointer;
            border: 1.303px solid #3a3a3a;
            background: #292929;
            color: #929292;
          }
        }
        &_cards {
          @apply flex gap-2 flex-col mt-2;

          &_card {
            @apply rounded-md p-4 w-full flex flex-col gap-2;
            border: 1px solid #242424;
            background: #0c0c0c;
            &_top {
              @apply flex justify-between gap-4;
              &_left {
                @apply flex flex-col;
              }
            }
            &_title {
              @apply text-lg text-white;
            }
            &_subtitle {
              @apply text-sm font-medium;
              color: #aaa;
            }
            &_workdet {
              @apply flex gap-1 text-xs items-center;
              &_1 {
                color: #adadad;
              }
              &_2 {
              }
              &_3 {
                color: #dedede;
              }
            }
            &_details {
              @apply flex gap-4 w-full items-center;
              &_desc {
                @apply text-sm xl:w-8/12;
                color: #c2c2c2;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1240px) and (max-width: 1559px) {
  .userprofile {
    width: 1200px;
  }
}
@media (min-width: 1560px) {
  .userprofile {
    width: 1350px;
  }
}
