.submissionsdrawer {
  &_width {
    @apply flex flex-col mx-auto;
    width: 96%;
  }
  &_closecontainer {
    @apply flex justify-end mb-6;
    &_closebutton {
      @apply rounded-full h-8 w-8 sm:h-12 sm:w-12 cursor-pointer text-lg sm:text-2xl font-bold;
      border: 1px solid #333;
      color: #878789;
      background: #181818;
    }
  }
  &_all {
    @apply flex flex-col gap-4;
    &_detailscontainer {
      @apply flex flex-col p-6 rounded-md;
      background: #161616;
      &_top {
        @apply flex justify-between h-fit items-center;
        &_left {
          @apply flex gap-2 h-fit text-sm sm:text-base;
          &_type {
            @apply rounded-md p-2;
            background-color: #252525;
            color: #7df5d1;
          }
          &_amount {
            @apply rounded-md p-2;
            background-color: #252525;
            color: #f7c487;
          }
        }
        &_right {
          @apply rounded-md bg-opacity-50 px-3 py-2 font-medium cursor-pointer;
          background: #ebebeb;
          color: #111;
        }
      }
      &_name {
        @apply text-white text-2xl sm:text-3xl font-medium mt-4;
      }
      &_submissions {
        @apply font-medium mt-6;
        color: #787878;
        span {
          @apply underline text-lg font-medium;
          color: #f8f8f8;
        }
      }
    }
    &_submissionscontainer {
      @apply flex flex-col lg:flex-row gap-3 w-full flex-wrap;
      &_card {
        @apply flex flex-col p-4 rounded-md;
        width: calc(50% - 6px);
        border: 1px solid #242424;
        background: #181818;
        &_name {
          @apply text-white text-lg sm:text-xl xl:text-2xl;
        }
        &_subtext {
          @apply mb-4 text-xs sm:text-sm xl:text-base;
          color: #c2c2c2;
        }
        &_other {
          @apply flex justify-end sm:justify-between items-center text-xs sm:text-sm xl:text-base;
          &_tags {
            @apply hidden sm:flex gap-1 xl:gap-2;
          }
          &_buttons {
            @apply flex gap-2 xl:gap-4;
            &_declare {
              @apply px-2 sm:px-3 py-1.5 sm:py-2 rounded-md cursor-pointer;
              border: 1px solid #404040;
              background: #222;
              color: #fff;
            }
            &_view {
              @apply px-2 sm:px-3 py-1.5 sm:py-2 rounded-md cursor-pointer;
              border: 1px solid #404040;
              background: #ebebeb;
              color: #111;
            }
          }
        }
      }
      @media (max-width: 1024px) {
        &_card {
          width: 100%;
        }
      }
    }
  }
  &_particular {
    @apply flex flex-col gap-8 h-full w-full overflow-hidden;
    &_subtext {
      @apply cursor-pointer w-fit;
      color: #8b8b8b;
    }
    &_left {
      @apply p-6 sm:p-8 rounded-xl flex flex-col w-full;
      border: 1px solid #141414;
      background: linear-gradient(180deg, #111 0%, #0c0c0c 100%);
      &_outer {
        @apply flex flex-col w-full p-1 rounded-xl;
        border: 1px solid #1b1b1b;
        background: #060606;
        &_outer {
          @apply flex flex-col p-1 rounded-xl h-fit;
          border: 1px solid #262626;
          width: 450px;

          background: linear-gradient(
            180deg,
            #0b0b0b 0%,
            rgba(11, 11, 11, 0.19) 100%
          );
        }
        @media (max-width: 1280px) {
          &_outer {
            width: 100%;
          }
        }
      }
      &_top {
        @apply flex gap-4;
      }
      &_profileimagecontainer {
        @apply hidden xl:flex h-40 w-40 rounded-full justify-center items-center font-black text-6xl;
        background-image: url("../../assets/images/profileimagebg.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #ae9afc;
      }
      &_namecontainer {
        @apply flex gap-3 mt-6 items-center;
        color: #dedede;

        &_name {
          @apply text-xl font-medium;
        }
        &_editbtn {
          @apply rounded-md px-2.5 py-2 cursor-pointer text-xs sm:text-base;
          border: 1px solid #575757;
          background: #151515;
        }
      }
      &_username {
        @apply text-sm mt-1;
        color: #737373;
      }
      &_socialcontainer {
        @apply flex flex-wrap gap-2 mt-4 text-xs md:text-sm xl:text-base;
        &_social {
          @apply px-2 py-1 gap-1 flex rounded-3xl items-center;
          border: 1px solid
            var(--ddnight-content-border-border-grey-100, #1d1e20);
          background: var(--ddnight-material-90, #131416);
          color: var(--ddnight-content-text-greys-60, #7e7e7e);
          img{
            @apply h-5 sm:h-6;
          }
        }
      }
      &_infocontainer {
        @apply flex flex-col gap-1 mt-5 sm:mt-8 text-xs md:text-sm xl:text-base;
        &_title {
          color: #595959;
        }
        &_value {
          color: #dedede;
        }
        &_tags {
          @apply flex gap-2 flex-wrap;
          &_tag {
            @apply rounded-3xl px-2 py-1;
            color: #dadada;
            border: 1px solid #2f2f2f;
            opacity: 0.8;
            background: #212121;
          }
        }
      }
    }
    &_right {
      @apply p-6 sm:p-8 rounded-xl flex flex-col h-full w-full;
      border: 1px solid #141414;
      background: linear-gradient(180deg, #111 0%, #0c0c0c 100%);
      &_declare {
        @apply flex justify-end;
        &_button {
          @apply px-3 py-2 rounded-md cursor-pointer;
          border: 1px solid #404040;
          background: #222;
          color: #fff;
        }
      }
      &_outer {
        @apply flex flex-col h-full p-1 rounded-xl w-full;
        border: 1px solid #1b1b1b;
        background: #060606;
        &_outer {
          @apply flex flex-col h-full p-1 rounded-xl;
          width: calc(100% - 482px);
          border: 1px solid #262626;
          background: linear-gradient(
            180deg,
            #0b0b0b 0%,
            rgba(11, 11, 11, 0.19) 100%
          );
        }
        @media (max-width: 1280px) {
          &_outer {
            width: 100%;
          }
        }
      }
      &_scrollcontainer {
        @apply flex flex-col overflow-y-scroll overflow-x-hidden;
      }
      &_infocontainer {
        @apply flex flex-col gap-1 mt-6 pl-2;
        &_title {
          @apply text-xl sm:text-2xl font-semibold;
          color: #f0f0f0;
        }
        &_value {
          @apply mt-1 text-sm sm:text-base;
          color: #dedede;
          background-color: #111 !important;
        }
      }
    }
  }
}
@media (min-width: 1240px) and (max-width: 1559px) {
  .submissionsdrawer_width {
    width: 1200px;
  }
}
@media (min-width: 1560px) {
  .submissionsdrawer_width {
    width: 1350px;
  }
}
