.profilemodal {
  @apply fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50;
  z-index: 9999;
  &_content {
    @apply text-white rounded-xl relative  w-[320px] sm:w-[500px] lg:w-[700px] overflow-hidden;
    background-color: #060606;
    border: 1px solid #2b2b2b;
    // width: 700px;
    &_header {
      @apply flex justify-between px-6 py-3 items-center rounded-t-xl;
      background: #171717;
      &_title {
        color: #d9d9d9;
        @apply font-medium;
      }
      &_cross {
        @apply text-2xl cursor-pointer font-black;
        color: #595959;
      }
    }
    &_main {
      @apply flex flex-col overflow-y-scroll overflow-x-hidden px-4 lg:px-6 w-full pr-2;

      &::-webkit-scrollbar {
        width: 0.5rem;
      }
      &::-webkit-scrollbar-track {
        background: #1d1e20;
      }

      &::-webkit-scrollbar-thumb {
        background: #2b2b2b;
        border-radius: 0.5rem;
      }

      height: 500px;
      &_container {
        @apply flex gap-5 mt-12 flex-col items-center pb-6 w-full;
        &_inputcontainer {
          @apply flex flex-col gap-2 w-full;
        }
        .date {
          color-scheme: dark;
        }
        &_checkbox {
          @apply p-2 ml-2;
          border: 1px solid #2b2b2b;
          background: #111;
          color: #dadada;
        }
        &_title {
          @apply text-lg;
          color: #bbb;
        }
        &_input {
          @apply rounded-md px-4 py-3 outline-none w-full;
          border: 1px solid #2b2b2b;
          background: #111;
          color: #dadada;
        }
        &_socials {
          @apply flex flex-col w-full gap-4;
          &_row {
            @apply flex flex-col lg:flex-row gap-4;
            &_input {
              @apply w-full lg:w-1/2 px-3 py-2 bg-inherit rounded-sm flex items-center;
              border: 1px solid
                var(--ddnight-content-border-border-grey-75, #2c2e31);
              color: var(--ddnight-content-text-greys-60, #7e7e7e);
              input {
                @apply bg-inherit outline-none pb-1;
                width: 150px;
                color: #eee;
              }
              #linkedin {
                @apply w-24;
              }
            }
            &_discordinput {
              @apply px-3 w-full py-2 bg-inherit rounded-sm flex items-center;
              border: 1px solid
                var(--ddnight-content-border-border-grey-75, #2c2e31);
              color: var(--ddnight-content-text-greys-60, #7e7e7e);
              input {
                @apply bg-inherit outline-none pb-1 w-80;
                color: #eee;
              }
              #linkedin {
                @apply w-24;
              }
            }
          }
        }
        &_submit {
          @apply rounded-md p-2.5 w-64 text-center mt-14 cursor-pointer;
          background: #f2f2f2;
          box-shadow: 0px 2px 1px 0px #fff inset,
            0px -2px 1px 0px rgba(10, 10, 10, 0.29) inset;
        }
      }
    }
    &_footer {
      @apply flex justify-end px-6 py-3 items-center rounded-b-xl;
      background: #171717;
      &_button {
        @apply rounded-md p-2.5 font-medium cursor-pointer;
        background: #f2f2f2;
        color: #1c1c1c;
        box-shadow: 0px 2px 1px 0px #fff inset,
          0px -2px 1px 0px rgba(10, 10, 10, 0.29) inset;
      }
    }
  }

}
.checkbox-label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  width: fit-content;
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: #111;
  border: 1px solid #ccc;
  @apply rounded-md;
  border: 1px solid #2b2b2b;
  background: #111;
  color: #dadada;
}
.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox-label input:checked ~ .checkbox-checkmark:after {
  display: block;
}
.checkbox-label .checkbox-checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #00bfff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
