.orgpage {
  @apply flex flex-col mx-auto;
  overflow-x: hidden;
  &_main {
    @apply flex flex-col mx-auto mt-48 gap-8;
    width: 90%;
    &_profilecontainer {
      border: 1px solid #141414;
      background: linear-gradient(180deg, #111 0%, #0c0c0c 100%);
      @apply flex flex-col md:flex-row gap-4 md:gap-16 p-6 justify-between rounded-xl;
      &_left {
        @apply flex gap-4;
        &_image {
          @apply w-20 h-20 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full flex justify-center items-center;
        }
        &_details {
          @apply flex flex-col gap-1 md:gap-2 my-1 md:my-4;
          &_titlecontainer {
            @apply flex gap-2 items-center;
            &_title {
              @apply text-xl md:text-2xl text-white font-semibold;
            }
            &_editbtn {
              @apply rounded-md text-sm md:text-base py-2 md:py-2.5 px-4 md:px-6 ml-2 md:ml-4 cursor-pointer;
              border: 1px solid #575757;
              background: #151515;
              color: #dedede;
            }
          }
          &_subtitle {
            color: #737373;
          }
          &_text {
            @apply hidden md:block;
            color: #9d9fa4;
          }
          &_status {
            @apply px-2 py-1 rounded-md w-fit text-xs md:text-base;
          }
        }
      }
      &_center {
        @apply flex flex-col  md:w-[30%] w-full;
        &_title {
          @apply font-semibold;
          color: #595959;
        }
        &_desc {
          @apply text-sm;
          color: #dedede;
        }
        &_socialcontainer {
          @apply mt-4 md:mt-8 flex gap-2 md:gap-4;
          &_social {
            @apply rounded-3xl flex p-2 gap-2 text-xs md:text-sm lg:text-base;
            border: 1px solid
              var(--ddnight-content-border-border-grey-100, #1d1e20);
            background: var(--ddnight-material-90, #131416);
            &_image {
            }
            &_text {
              color: #7e7e7e;
            }
          }
        }
      }
      &_right {
        @apply lg:flex flex-col hidden;
        width: 30%;
        &_title {
          color: #595959;
        }
        &_tags {
          @apply flex flex-wrap gap-2 mt-2;
          &_tag {
            @apply rounded-3xl px-2 py-1;
            border: 1px solid #2f2f2f;
            color: #dadada;
            opacity: 0.8;
            background: #212121;
          }
        }
      }
    }
    &_infocontainer {
      @apply flex flex-col p-6;
      border: 1px solid #141414;
      background: linear-gradient(180deg, #111 0%, #0c0c0c 100%);
      &_tabscontainer {
        @apply rounded-xl p-1 flex justify-between items-center gap-1 text-sm sm:text-base;
        &_left {
          @apply flex gap-1;
        }
        &_right {
          @apply font-medium flex rounded-md gap-2 px-3 py-2 cursor-pointer;
          background: #90f7c5;
          color: #111;
        }
        &_tab {
          @apply px-6 py-3 rounded-lg;
          color: rgba(218, 218, 218, 0.5);
          span {
            @apply px-2 py-1 ml-1 rounded-3xl text-lg;
            background: #272727;
          }
        }
        .active {
          @apply text-white;
          border: 1px solid #3a3a3a;
          background: #1a1a1a;
        }
      }
      &_cardscontainer {
        @apply flex flex-col w-full;
        &_cards {
          @apply flex flex-col gap-4 mt-4 md:mt-8;
          &_card {
            @apply p-4 gap-4 rounded-xl flex flex-col;
            border: 1px solid #252525;
            background: #111;
            &_top {
              @apply flex flex-col lg:flex-row gap-4;
            }
            &_bottom {
              @apply flex justify-between;
            }
            &_left {
              @apply flex gap-4  lg:w-[70%] w-full;
              &_image {
                @apply h-12 w-12 sm:h-24 sm:w-24  md:h-32 md:w-32;
                img {
                  @apply w-full h-full object-cover;
                }
              }
              &_details {
                @apply flex flex-col font-medium;
                width: calc(100% - 128px);
                &_top {
                  @apply flex gap-2 mt-2 md:mt-4 text-sm md:text-base;
                  &_type {
                    @apply rounded-md p-2;
                    background-color: #252525;
                    color: #7df5d1;
                  }
                  &_amount {
                    @apply rounded-md p-2;
                    background-color: #252525;
                    color: #f7c487;
                  }
                }
                &_name {
                  @apply text-white font-semibold mt-2;
                }
                &_by {
                  @apply text-xs mt-0.5;
                  color: #adadad;
                }
                &_desc {
                  @apply hidden sm:flex text-sm mt-3;
                  color: #878787;
                }
              }
              @media (max-width: 640px) {
                &_details {
                width: calc(100% - 48px);
                }
              }
              @media (min-width: 640px) and (max-width: 768px) {
                &_details {
                width: calc(100% - 96px);
                }
              }
            }
            &_right {
              @apply flex items-end  w-full lg:w-[30%] flex-col;
              &_buttoncontainer {
                @apply flex items-end flex-row gap-4 lg:mb-6 text-sm md:text-base;
                &_button {
                  @apply rounded-md py-2 px-3 cursor-pointer text-center w-fit;
                  border: 1px solid #494949;
                  background: #272727;
                  color: #dedede;
                }
              }
              &_submission {
                @apply rounded-md w-fit h-fit p-2 text-xs md:text-base;
                background: rgba(142, 212, 141, 0.12);
                color: #8ed48d;
              }
              &_tags {
                @apply hidden md:flex gap-2 mt-4 mb-2;
                &_tag {
                  @apply rounded-3xl py-1 px-2;
                  color: #9d9d9d;

                  border: 1px solid #323232;
                  background: #252525;
                }
              }
              &_date {
                @apply text-xs md:text-sm xl:text-base;

                color: #787878;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1240px) and (max-width: 1559px) {
  .orgpage {
    width: 1200px;
  }
}
@media (min-width: 1560px) {
  .orgpage {
    width: 1350px;
  }
}
