.registerorg {
  @apply flex justify-center mx-auto h-screen overflow-hidden;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../assets/images/circlebg.png);
  background-size: cover;
  &_main {
    @apply flex mt-48 sm:mt-44 items-center flex-col w-10/12;
    &_registerorg {
      @apply opacity-75 text-center font-medium text-base sm:text-2xl;
      color: #b09dfc;
    }
    &_title {
      @apply text-white opacity-75 text-center font-medium text-xl sm:text-5xl;
    }
    &_subtitle {
      @apply opacity-75 text-center font-medium sm:mt-4 text-sm sm:text-xl sm:w-5/12;
      color: #636363;
    }
    &_container {
      @apply flex gap-1 sm:gap-5 mt-2 sm:mt-12 flex-col items-center overflow-y-scroll overflow-x-hidden mb-24 md:mb-52 xl:mb-20 pb-2 sm:pb-6 pr-2;
      width: 100%;

      &::-webkit-scrollbar {
        width: 0.5rem;
      }

      &::-webkit-scrollbar-track {
        background: #1d1e20;
      }

      &::-webkit-scrollbar-thumb {
        background: #2b2b2b;
        border-radius: 0.5rem;
      }

      &_inputcontainer {
        @apply flex flex-col gap-1 sm:gap-2 w-full;
      }
      &_imginputcontainer {
        @apply flex gap-8 items-center mt-2;
        &_imgpreview {
          @apply w-20 h-20 md:w-24 md:h-24 rounded-full cursor-pointer;
          border: 1px solid #1d1e20;
          background-image: url('../../assets/images/emptyimg.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          img {
            @apply w-full h-full object-cover;
          }
        }
        &_imgupload {
          @apply flex flex-col gap-2;
          input[type="file"] {
            display: none;
          }
          .custom-file-label {
            @apply rounded-sm p-2 text-sm w-32 text-center cursor-pointer;
            border: 1px solid
              var(--ddnight-content-border-border-grey-75, #2c2e31);
            background: var(--ddnight-content-button-secondary-rest, #16181d);
            color: var(--ddnight-content-button-secondary-foreground, #afafaf);
          }
          div {
            @apply text-sm;
            color: var(--ddnight-content-text-greys-60, #7e7e7e);
          }
        }
      }
      &_title {
        @apply text-base sm:text-lg mt-1 sm:mt-0;
        color: #bbb;
      }
      &_input {
        @apply rounded-md px-4 py-2 sm:py-3 outline-none text-sm sm:text-base;
        border: 1px solid #2b2b2b;
        background: #111;
        width: auto;
        color: #efefef;
      }
      &_submit {
          @apply rounded-md p-2.5 w-64 text-center mt-4 sm:mt-14 cursor-pointer text-black;
        background: #f2f2f2;
        box-shadow: 0px 2px 1px 0px #fff inset,
          0px -2px 1px 0px rgba(10, 10, 10, 0.29) inset;
      }
    }
  }
  &_success {
    @apply flex mt-44 xl:mt-56 items-center flex-col w-10/12;
    &_icon {
      @apply w-80 h-80;
      img {
        @apply h-full w-full;
      }
    }
    &_title {
      @apply text-xl sm:text-2xl text-white font-medium mt-2 sm:mt-4 xl:mt-12 text-center;
    }
    &_subtitle {
      @apply mt-1 sm:mt-2 w-10/12 xl:w-2/12 text-center text-sm sm:text-base;
      color: #9d9fa4;
    }
    &_submit {
      @apply rounded-md p-2.5 w-64 text-center mt-4 sm:mt-8 xl:mt-12 cursor-pointer;
      background: #f2f2f2;
      box-shadow: 0px 2px 1px 0px #fff inset,
        0px -2px 1px 0px rgba(10, 10, 10, 0.29) inset;
    }
  }
}
@media (min-width: 768px) and (max-width: 1239px) {
  .registerorg_main {
    width: 600px;
  }
}

@media (min-width: 1240px) and (max-width: 1559px) {
  .registerorg_main {
    width: 700px;
  }
}
@media (min-width: 1560px) {
  .registerorg_main {
    width: 750px;
  }
}
