.gigdetailpage {
  &_width {
    @apply flex flex-col mx-auto;
  }
  &_closecontainer {
    @apply flex justify-end;
    &_closebutton {
      @apply rounded-full h-8 w-8 sm:h-12 sm:w-12 cursor-pointer text-base sm:text-2xl font-bold;
      border: 1px solid #333;
      color: #878789;
      background: #181818;
    }
  }
  &_main {
    @apply mx-auto flex flex-col sm:flex-row gap-4 sm:gap-8 mt-4 w-full;
    &_left {
      width: 75%;
      @apply flex flex-col rounded-xl pb-4;
      border: 1px solid #141414;
      background: linear-gradient(180deg, #111 0%, #0c0c0c 100%);
      &_top {
        @apply flex gap-2 mb-1 sm:mb-8 px-4 sm:px-10 pt-4 sm:pt-10;
        &_type {
          @apply rounded-md p-2 text-xs sm:text-base;
          background-color: #252525;
          color: #7df5d1;
        }
        &_amount {
          @apply rounded-md p-2 text-xs sm:text-base;
          background-color: #252525;
          color: #f7c487;
        }
      }
      &_name {
        @apply text-white font-semibold text-xl sm:text-4xl mt-2 px-4 sm:px-10;
      }
      &_creatordetails {
        @apply flex gap-2 sm:gap-4 mt-2 sm:mt-4 items-center px-4 sm:px-10;
        color: #adadad;

        &_image {
          @apply h-6 w-6;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      &_tagsubmissioncontainer {
        @apply flex justify-between px-3 sm:px-10 sm:mb-4;
        &_tagcontainer {
          @apply flex gap-2 mt-3 sm:mt-6 mb-2;
          &_tag {
            @apply rounded-3xl py-1 px-2;
            color: #9d9d9d;

            border: 1px solid #323232;
            background: #252525;
          }
        }
      }
      &_divider {
        @apply w-full flex h-1;
        background: #181818;
      }
      &_detailscontainer {
        @apply flex flex-col p-4 sm:p-10;
        &_subtext {
          @apply text-sm mb-2 sm:mb-10 cursor-pointer w-fit flex;
          color: #898989;
        }
        &_title {
          @apply text-sm sm:text-2xl font-semibold;
          color: #f0f0f0;
        }
        &_value {
          @apply py-2 sm:py-3 rounded-md bg-inherit;
          background: linear-gradient(180deg, #111 0%, #0c0c0c 100%) !important;
        }
        &_submitform {
          @apply flex justify-end mt-2 sm:mt-10;
          &_btn {
            @apply rounded-md w-36 sm:w-72 p-2.5 h-fit text-center font-bold cursor-pointer;
            background: #f2f2f2;
            box-shadow: 0px 2px 1px 0px #fff inset,
              0px -2px 1px 0px rgba(10, 10, 10, 0.29) inset;
          }
        }
      }
      &_success {
        @apply flex mt-16 sm:mt-24 items-center flex-col;
        &_icon {
          @apply h-20 w-20 sm:w-80 sm:h-80;
          img {
            @apply h-full w-full;
          }
        }
        &_title {
          @apply text-lg sm:text-2xl text-white font-medium sm:mt-12;
        }
        &_subtitle {
          @apply sm:mt-2 w-7/12 sm:w-4/12 text-center;
          color: #9d9fa4;
        }
        &_submit {
          @apply rounded-md p-2.5 w-64 text-center mt-14 cursor-pointer;
          background: #f2f2f2;
          box-shadow: 0px 2px 1px 0px #fff inset,
            0px -2px 1px 0px rgba(10, 10, 10, 0.29) inset;
        }
      }
    }
    &_right {
      @apply flex flex-col gap-4 sm:gap-8;
      width: 25%;
      &_container {
        @apply flex flex-col rounded-xl px-8 py-5;
        border: 1px solid #141414;
        background: linear-gradient(180deg, #111 0%, #0c0c0c 100%);
        height: 200px;
      }
      &_steppercontainer {
        @apply flex flex-col rounded-xl px-8 py-2 sm:py-5;
        border: 1px solid #141414;
        background: linear-gradient(180deg, #111 0%, #0c0c0c 100%);
      }
      .submission {
        @apply justify-between h-auto p-4;
        &_title {
          @apply text-sm sm:text-lg font-semibold;
          color: #787878;
        }
        &_value {
          @apply text-xl sm:text-3xl font-semibold;
          color: #f8f8f8;
        }
        &_button {
          @apply rounded-md w-full p-2.5 h-fit text-center font-bold cursor-pointer mt-2 sm:mt-4;
          background: #f2f2f2;
          box-shadow: 0px 2px 1px 0px #fff inset,
            0px -2px 1px 0px rgba(10, 10, 10, 0.29) inset;
        }
      }
      .prize {
        &_title {
          @apply text-sm sm:text-lg font-semibold;
          color: #787878;
        }
        &_info {
          @apply text-xl sm:text-3xl font-semibold;
          color: #f8f8f8;
        }
      }
      .project {
        &_title {
          @apply font-medium;
          color: #595959;
        }
        &_detail {
          @apply flex gap-5 mt-4;
          &_image {
            @apply w-16 h-16 rounded-full flex justify-center items-center;
            border: 2px solid #73e870;
            img {
              @apply h-4/5 w-4/5 rounded-full object-cover;
            }
          }
          &_others {
            @apply flex flex-col gap-2;
            &_data {
              @apply flex gap-2 items-center;
              &_name {
                @apply text-xl text-white font-medium;
              }
              &_status {
                @apply px-2 py-1 rounded-md;
                color: #73e871;
                background: rgba(115, 232, 113, 0.12);
              }
            }
            &_tags {
              @apply flex flex-wrap gap-2;
              &_tag {
                @apply rounded-3xl px-2 py-1;
                border: 1px solid #2f2f2f;
                color: #dadada;
                opacity: 0.8;
                background: #212121;
              }
            }
          }
        }
      }
      .eligibility {
        &_title {
          @apply text-lg sm:text-xl font-semibold;
          color: #fbfbfb;
        }
        &_text {
          @apply mt-2;
          color: #afafaf;
        }
      }
    }

    @media (max-width: 1024px) {
      &_right,
      &_left {
        width: 100%;
      }
    }
  }
}

.out {
  animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
@keyframes quickScaleDown {
  0% {
    transform: scale(1);
  }
  99.9% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes scaleDown {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
  100% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }
}
@keyframes slideIn {
  0% {
    transform: translateY(400px);
    animation-timing-function: ease-out;
  }
  60% {
    transform: translateY(-30px);
    animation-timing-function: ease-in;
  }
  80% {
    transform: translateY(10px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-in;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.MuiDrawer-paper {
  @apply p-8;
  height: 90vh !important;
  background: linear-gradient(180deg, #0d0d0d 0%, #000 100%);
  border-radius: 14px 14px 0px 0px;
}
@media (min-width: 1240px) and (max-width: 1559px) {
  .gigdetailpage_width {
    width: 1200px;
  }
}
@media (min-width: 1560px) {
  .gigdetailpage_width {
    width: 1350px;
  }
}
