.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease; 
}

.unhide {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s ease; 
}

.top_div {
  width: 100%;
}

@media (min-width: 721px) {
  .top_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
}

.top_div_container {
  width: 100%;
  background-repeat: no-repeat;
  background-position: relative;
  background-image: url(../../assets/images/top_div_container_bg.png);
  background-size: contain;
  overflow: hidden;

  @media (max-width: 720px) {
      background-image: url(../../assets/images/Ellipse.png);
    }
}

.title {
  margin-top: 17rem;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 14px;
  padding-right: 14px;
  width: 800px;
  font-size: 3.5rem;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  background: linear-gradient(94.54deg, #FFFFFF -5.12%, rgba(255, 255, 255, 0.42) 23.95%, #FFFFFF 61.46%, rgba(255, 255, 255, 0.08) 99.21%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;


  
  @media (max-width: 720px) {
    width: 100%;
    text-align: left;
    margin-top: 10rem;
    margin-left: 0rem;
    padding-left: 2rem;
    margin-right: 5rem;
    padding-right: 1rem;
    padding-bottom: 3rem;
    font-size: 2.7rem;
    }
    
}

.title_para {
  margin-top: 0.5rem;
  margin-bottom: 5rem;
  font-size: 21px;
  font-weight: bold;
  color: #6E6D7A;
  padding: 5px;
  text-align: center;

  @media (max-width: 720px) {
    display: none;
    }
}

.explore_button_container {
  text-align: left; 
  @media (max-width: 720px) {
      padding-left: 2rem;
      padding-top: 2rem;
    }
}

.explore_button button {
  background: linear-gradient(to right, #171717 , #161616, #151515, #141414, #131313, #121212, #111111, #101010, #0F0F0F, #0E0E0E, #0D0D0D, #0C0C0C, #0B0B0B, #0A0A0A);

  border: 1px solid #000;
  border-radius: 3rem;

  box-shadow: inset 0px 4px #313131;

  padding: 1rem;
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
}

.explore_button h1 {
  color: transparent;
  -webkit-background-clip: text;
  text-align: center;
  background-clip: text;
  background-image: linear-gradient(to right, #75D3F0, #B6F1A6);

  @media (max-width: 720px) {
    color: #7D7D7D;
    background: none;
  }
}



.circle_div {
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin-top: 10rem; 
  background-repeat: no-repeat;
  background-position: relative;
  background-image: url(../../assets/images/circle_div_bg.png);
  background-size: 100%;
  margin-bottom: 5rem;
  padding-left: 3vw;
  padding-right: 3vw;

  @media (max-width: 720px) {
    padding: 0;
  }
  
  img {
    width: 60rem;
    height: auto;
    overflow: hidden;

    @media (max-width: 1600px) {
      width: 55rem;
    }

    @media (max-width: 720px) {
      content: url(../../assets/images/circle_small.png);
      margin: 0;
      object-fit: contain;
    }
    
  }
}

.whatis_container {
  display: flex;
  justify-content: space-between;

  @media (max-width: 1366px) {
    flex-direction: column-reverse; 
    justify-content: right;
    margin-left: 10vw;
    margin-top: 10vw;
    margin-bottom: 10vw;
  }

  @media (max-width: 1600px) {
    margin-left: 10vw;
  }

  margin-left: 20vw;
  margin-top: 12rem;
}

.left {
  width: 60%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1366px) {
    width: 100%;
    padding-right: 5vw;
  }

  

  &_top {
    height: 20%;
    padding-bottom: 10vw;
    

  @media (max-width: 1366px) {
    height: 100%;
  }


    &_bottom {
      color: white;
      font-weight: 600;
      font-size: 2.6rem;

      @media (max-width: 1366px) {
        padding-bottom: 5vw;
      }

      @media (max-width: 720px) {
        line-height: normal;
      }
    }
  }

  &_bottom {
    height: 60%;
    display: flex;
    flex-direction: column;
    color: #AEAEAE;
    font-weight: bold;
    font-size: x-large;
    padding-right: 10vw;

    @media (max-width: 720px) {
      font-size: medium;
    }
  }
}

.right {
  width: 35%;
  overflow: hidden;


  @media (max-width: 1366px) {
    width: 60%;
    margin-left: 37vw;
  }
  
  img {
    margin-top: -47%; /* Move the image up by 25% to hide the top portion */
    size: contain;
    overflow: hidden;
    @media (max-width: 1366px) {
      width: 60vw;
      margin-top: -30%;
      margin-bottom: -10%;
    }
  }
}

@media (max-width: 720px) {
  .right {
    display: none;
  }
}

.toolkit {
  @apply flex justify-center items-center flex-col w-full;
  background-image: url(../../assets/images/toolkit_bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 5rem;

  &_text {
    color: white;
    font-size: 2.5rem;
    font-weight: 600;
    margin-left: 20vw;
    margin-bottom: 6rem;
    padding-top: 2rem;
    padding-right: 20vw;

    @media (max-width: 1366px) {
      padding-left: 5vw;
      padding-right: 5vw;
      margin-left: 5vw;
      font-size: 2rem;
    }

    @media (max-width: 1600px) {
      padding-left: 5vw;
      padding-right: 20vw;
      margin-left: 5vw;
      font-weight: 400;
    }

  }
  &_png {
    width: 100%;
    display: flex;
    justify-content: right; 
    overflow: hidden;

    @media (max-width: 1366px) {
      width: 100%;
    }

    img {
      width: 88%;
      margin-right: -2%;

      @media (max-width: 1366px) {
        content: url(../../assets/images/toolkit_png_small.png);
        margin: 0;
        object-fit: contain;
      }
    }
  }
}

.offerings {
  margin-bottom: 5rem;
  &_png {
    display:flex;
    justify-content: center;
  }
  &_text {
    color: white;
    font-size: 2.5rem;
    margin-left: 20vw;
    font-weight: 500;
    margin-bottom: 1.5rem;
    line-height: 1.1;

    @media (max-width: 1366px) {
      margin-left: 10vw;
    }
  }
  &_title {
    margin-left: 20vw;

    @media (max-width: 1366px) {
      margin-left: 10vw;
    }
  }
}

.parent {
  display: flex;
  justify-content: center;
  padding: 4vw;
  padding-top: 2vw;
}

.left-child {
  padding-right: 0.5vw;
  border: 2px solid #262626;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  
  img {
    margin-bottom: -20%;
    height: 100%;
    width: auto;
  }
}

.right-child {
  display: flex;
  flex-direction: column; 
  padding-left: 0.5vw;
}

.top {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  margin-bottom: 1vh;

  &_left {
    margin-right: 0.5vw;
    border: 2px solid #262626;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &_right {
    position: relative;
    display: inline-block;
    margin-left: 0.5vw;
    border: 2px solid #262626;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;

    &_bg {
      display: block;
      width: auto;
      height: auto;
    }

    &_stellar {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-167%, -40%);
      width: auto; 
      height: auto;

      @media (max-width: 1366px) {
        width: 30%;
      }
    }

    &_flow {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-80%, -80%);
      width: auto; 
      height: auto;

      @media (max-width: 1366px) {
        width: 30%;
      }
    }

    &_cosmos {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-25%, -4%);
      width: auto; 
      height: auto;

      @media (max-width: 1366px) {
        width: 30%;
      }
    }
  }
}

.middle {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  position: relative; 
  margin-top: 1vh;
  margin-bottom: 1vh;

  &_left {
    position: relative;
    display: inline-block;
    margin-right: 0.5vw;
    border: 2px solid #262626;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    &_bg {
      display: block;
      width: 100%;
      height: auto;
    }

    &_circle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto; 
      height: auto;

      @media (max-width: 1366px) {
        width: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &_right {
    position: relative;
    display: inline-block;
    border: 2px solid #262626;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;

    &_bg {
      display: block;
      width: 100%;
      height: auto;
    }

    &_card {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-41%, -65%);
      width: auto; 
      height: auto;
    }
  }
}

.bottom {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  margin-top: 1vh;

  &_left {
    margin-right: 1rem;
    position: relative;
    display: inline-block;
    border: 2px solid #262626;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;

    &_bg {
      display: block;
      width: auto;
      height: auto;
    }

    &_trophy {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-1%, -51%);
      width: auto; 
      height: auto;

      @media (max-width: 1366px) {
        width: 50%;
      }
    }
  }

  &_right {
    margin-left: 1rem;
    position: relative;
    display: inline-block;
    border: 2px solid #262626;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;

    &_bg {
      display: block;
      width: auto;
      height: auto;
    }

    &_coins {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-10%, -50%);
      width: auto; 
      height: auto;
    }
  }
}

.top_right_stellar:hover {
  transform: translate(-167%, -40%) scale(1.1);
  transition: transform 0.5s ease;
}

.top_right_flow:hover {
  transform: translate(-80%, -80%) scale(1.1);
  transition: transform 0.5s ease;
}

.top_right_cosmos:hover {
  transform: translate(-25%, 0) scale(1.1);
  transition: transform 0.5s ease;
}

.middle_left_circle:hover {
  transform: translate(-50%, -50%) scale(1.1); /* Adjust the scale factor as needed */
  transition: transform 0.5s ease; /* Add a smooth transition effect */
}

.middle_right_card:hover {
  transform: translate(-41%, -65%) scale(1.1); /* Adjust the scale factor as needed */
  transition: transform 0.5s ease; /* Add a smooth transition effect */
}

.bottom_left_trophy:hover {
  transform: translate(-1%, -51%) scale(1.1); /* Adjust the scale factor as needed */
  transition: transform 0.5s ease; /* Add a smooth transition effect */
}

.bottom_right_coins:hover {
  transform: translate(-10%, -50%) scale(1.1); /* Adjust the scale factor as needed */
  transition: transform 0.5s ease; /* Add a smooth transition effect */
}

@media (max-width: 720px) {
  .right, .toolkit, .offerings {
    display: none;
  }
}

.parent2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 20vw;
  margin-right: 20vw;
  margin-top: 5rem;
  margin-bottom: 3vw;
  // padding-left: 20vw;
  // padding-right: 20vw;

  @media (max-width: 1700px) {
    margin-left: 10vw;
    margin-right: 10vw;
  }

  @media (max-width: 1366px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10vw;
    padding-right: 10vw;
  }

  @media (max-width: 720px) {
    margin-bottom: 15vh;
  }
}

@media (max-width: 720px) {
  .parent2 {
    padding: 0; /* Set padding to 0 for screens below 720px */
  }
}

.top2 {
  display: flex;
  margin-bottom: 1.5vh;

  @media (max-width: 1366px) {
    flex-direction: column-reverse;
    margin-top: 4rem;
    margin-bottom: 10rem;
  }

  &_left {
    margin-right: 1vw;
    border: 2px solid #262626;
    border-radius: 20px;
    object-fit: contain;

    @media (max-width: 1366px) {
      margin-top: -20%;
      z-index:1;
      margin-right: 0;
      box-shadow: 0px -8px 15px rgba(0, 0, 0, 1);
    }

    &_button {
      margin-left: 1.5rem;
      margin-top: 2rem;
      margin-bottom: 4rem;

      @media (max-width: 720px) {
        margin-top: 5vw;
        margin-bottom: 4vw;
      }
    }
    
    .bulletin-item {
      list-style: none; 
      padding-left: 2rem; 
      margin-bottom: 10px; 
      color: #E6E6E6;
      font-size: medium;
      display: flex; 
      align-items: center;
      padding-right: 4rem;

      @media (max-width: 720px) {
        font-size: medium;
        padding-right: 0;
        padding-left: 0;
      }
    }

    .bulletin-item::before {
      content: url(../../assets/images/top_bullet.png);
      font-size: 20px; 
      display: inline-block;
      width: 20px;
      text-align: center; 
      margin-left: 0.5rem; 
      margin-right: 1rem;
      
    }

    .top2_left_title:hover {
      color: #95F6D3; /* Change font color on hover */
      transition: color 0.5s ease-out;
    }

    &_title {
      font-size: 2.3rem;
      font-weight: 800;
      color: #545454;
      padding-left: 2rem;
      padding-right: 19rem;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;

      @media (max-width: 720px) {
        font-size: 1.5rem;
        padding-right: 0;
        padding-left: 10vw;
        padding-top: 5vw;
        padding-bottom: 5vw;
      }
    }

    background: linear-gradient(to bottom, #111111, #0C0C0C);
      
  }

  &_right {
    border: 2px solid #262626;
    border-radius: 20px;
    &_img {
      width: 100%;
      height: 100%;
    }
  }
}

.middle2 {
  display: flex;
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;

  @media (max-width: 1366px) {
    flex-direction: column;
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  &_left {
    flex-grow: 1; 
    border: 2px solid #262626;
    border-radius: 20px;
    margin-right: 1vw;
    &_img {
      width: 100%;
      height: 100%;
    }
  }

  &_right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 2px solid #262626;
    border-radius: 20px;

    @media (max-width: 1366px) {
      margin-top: -20%;
      z-index:1;
      margin-left: 0;
      box-shadow: 0px -8px 15px rgba(0, 0, 0, 1);
    }

    &_button {
      margin-left: 1.5rem;
      margin-top: 2rem;
      margin-bottom: 4rem;

      @media (max-width: 720px) {
        margin-top: 5vw;
        margin-bottom: 4vw;
      }
    }

    .bulletin-item {
      list-style: none; 
      padding-left: 2rem; 
      margin-bottom: 10px; 
      color: #E6E6E6;
      font-size: medium;
      display: flex; 
      align-items: center;
      padding-right: 4rem;

      @media (max-width: 720px) {
        font-size: medium;
        padding-right: 0;
        padding-left: 0;
      }
    }

    .bulletin-item::before {
      content: url(../../assets/images/middle_bullet.png);
      font-size: 20px;
      display: inline-block;
      width: 20px;
      text-align: center;
      margin-left: 0.5rem;
      margin-right: 1rem;
    }

    .middle2_right_title:hover {
      color: #b37ff4;
      /* Change font color on hover */
      transition: color 0.5s ease-out;
    }

    &_title {
      font-size: 2.3rem;
      font-weight: 800;
      color: #545454;
      padding-left: 2rem;
      padding-right: 10vw;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      @media (max-width: 720px) {
        font-size: 1.5rem;
        padding-right: 0;
        padding-left: 10vw;
        padding-top: 5vw;
        padding-bottom: 5vw;
      }
    }

    background: linear-gradient(to bottom, #111111, #0c0c0c);
  }
}


.bottom2 {
  display: flex;
  margin-top: 1.5vh;
  @media (max-width: 1366px) {
    flex-direction: column-reverse;
    margin-top: 10rem;
    margin-bottom: 4rem;
  }

  &_left {
    margin-right: 1vw;
    border: 2px solid #262626;
    border-radius: 20px;

    @media (max-width: 1366px) {
      margin-top: -20%;
      z-index:1;
      margin-right: 0;
      box-shadow: 0px -8px 15px rgba(0, 0, 0, 1);
    }

    &_button {
      margin-left: 1.5rem;
      margin-top: 2rem;
      margin-bottom: 4rem;

      @media (max-width: 720px) {
        margin-top: 5vw;
        margin-bottom: 4vw;
      }
    }
    
    .bulletin-item {
      list-style: none; 
      padding-left: 2rem; 
      margin-bottom: 10px; 
      color: #E6E6E6;
      font-size: medium;
      display: flex; 
      align-items: center;
      padding-right: 4rem;

      @media (max-width: 720px) {
        font-size: medium;
        padding-right: 0;
        padding-left: 0;
      }
    }

    .bulletin-item::before {
      content: url(../../assets/images/bottom_bullet.png);
      font-size: 20px; 
      display: inline-block;
      width: 20px;
      text-align: center; 
      margin-left: 0.5rem; 
      margin-right: 1rem;
    }

    .bottom2_left_title:hover {
      color: #F4CC3B; /* Change font color on hover */
      transition: color 0.5s ease-out;
    }

    &_title {
      font-size: 2.3rem;
      font-weight: 800;
      color: #545454;
      padding-left: 2rem;
      padding-right: 10vw;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;

      @media (max-width: 720px) {
        font-size: 1.5rem;
        padding-right: 0;
        padding-left: 10vw;
        padding-top: 5vw;
        padding-bottom: 5vw;
      }
    }

    background: linear-gradient(to bottom, #111111, #0C0C0C);
  }

  &_right {
    flex-grow: 1;
    border: 2px solid #262626;
    border-radius: 20px;
    &_img {
      width: 100%;
      height: 100%;
    }
  }
  
}

.flow {

  @media (max-width: 720px) {
    display: none;
  }

  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: center;
  
  &_title {
    color: white;
    font-size: 3.5rem;
    font-weight: 600;

    @media (max-width: 1366px) {
      font-size: 3rem;
      font-weight: normal;
    }
  }

  &_img {
    object-fit: contain;
    width: 78%;

    padding-top: 5rem;
    padding-bottom: 10rem;
    padding-left: 7rem;
    padding-right: 7rem;

    @media (max-width: 1366px) {
      padding-top: 5rem;
      padding-bottom: 5rem;
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }
}

.footer {
  border-top: 2px solid #262626;
  background-image: url(../../assets/images/footer_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 70%;

  @media (max-width: 720px) {
    background-image: url(../../assets/images/footer_bg_xsmall.png);
    background-size: contain;
  }

  @media (max-width: 1366px) {
    background-image: url(../../assets/images/footer_bg_small.png);
    background-size: cover;
  }

  &_title_small {
      margin-top: 4rem;
      padding-right: 4rem;
      margin-left: 10vw;
      padding-top: 1rem;
      padding-bottom: 3vh;

      img {
        margin-left: 2rem;
      }
  
      @media (max-width: 720px) {
        display: block;
        padding: 0;
        padding-left: 2rem;
      }

      .subtitle {
        color: #5B5B5B;
        padding-top: 1vh;
        font-weight: bold;
        font-size: medium;
        margin-left: 2rem;
      }

      @media (max-width: 720px) {
        display: none;
      }

      @media (min-width: 1366px) {
        display: none;
      }
  }

  &_top {
    display: flex;
    justify-content: center;
    margin-left: 10vw;
    margin-right: 10vw;
    padding-top: 1rem;
    padding-bottom: 3vh;

    @media (max-width: 720px) {
      display: block;
      padding: 0;
      padding-left: 2rem;
    }

    &_title_large {
      margin-top: 4rem;
      padding-right: 4rem;

      .subtitle {
        color: #5B5B5B;
        padding-top: 1vh;
        font-weight: 800;
        font-size: 0.9rem;
      }
      
      @media (min-width: 721px) and (max-width: 1366px) {
        display: none;
      }
    }

    button {
      padding-top: 1rem;
    }

    .div2 {
      margin-left: 2rem;
      margin-right: 5rem;
      margin-top: 5rem;

      @media (max-width: 720px) {
        margin-left: 0rem;
        margin-right: 0;
      }

      &_1 {
        color: #C9C9C9;
        margin-bottom: 0.5rem;
        font-weight: bold;
        font-size: small;
        letter-spacing: 1px;
      }

      &_2 {
        color: #5B5B5B;
        margin-bottom: 0.4rem;
        font-weight: bold;
        font-size: small;
        letter-spacing: 1px;
      }

      &_3 {
        color: #5B5B5B;
        margin-bottom: 0.4rem;
        font-weight: bold;
        font-size: small;
        letter-spacing: 1px;
      }

      &_4 {
        color: #5B5B5B;
        margin-bottom: 0.4rem;
        font-weight: bold;
        font-size: small;
        letter-spacing: 1px;
      }

    }

    .div3 {
      margin-left: 3rem;
      margin-right: 3rem;
      margin-top: 5rem;

      @media (max-width: 720px) {
        margin-left: 0rem;
      }

      &_1 {
        color: #C9C9C9;
        margin-bottom: 0.5rem;
        font-weight: bold;
        font-size: small;
        letter-spacing: 1px;
      }

      &_2 {
        color: #5B5B5B;
        margin-bottom: 0.4rem;
        font-weight: bold;
        font-size: small;
        letter-spacing: 1px;

        span {
          color: #02B269
        }
      }

      &_3 {
        color: #5B5B5B;
        margin-bottom: 0.4rem;
        font-weight: bold;
        font-size: small;
        letter-spacing: 1px;

        span {
          color: #AE9AFC
        }
      }

      &_4 {
        color: #5B5B5B;
        margin-bottom: 0.4rem;
        font-weight: bold;
        font-size: small;
        letter-spacing: 1px;

        span {
          color: #A3A0D2
        }
      }
    }

    .div4 {
      margin-left: 5rem;
      margin-right: 2rem;
      margin-top: 5rem;

      @media (max-width: 720px) {
        margin-left: 0rem;
      }

      &_1 {
        color: #C9C9C9;
        margin-bottom: 0.5rem;
        font-weight: bold;
        font-size: small;
        letter-spacing: 1px;
      }

      &_2 {
        color: #5B5B5B;
        margin-bottom: 0.4rem;
        font-weight: bold;
        font-size: small;
        letter-spacing: 1px;
      }

      &_3 {
        color: #5B5B5B;
        margin-bottom: 0.4rem;
        font-weight: bold;
        font-size: small;
        letter-spacing: 1px;
      }
    }
  }

  &_bottom {
    display: flex;
    align-items: flex-start;
    padding-bottom: 3vw;

    .div1 {
      padding-top: 3rem;
      color: #5B5B5B;
      font-weight: bold;
      padding-left: 20vw;
      font-size: 0.8rem;
      letter-spacing: 1px;

      @media (max-width: 720px) {
        display: none;
      }
    }

    .div2 {
      color: #5B5B5B;
      font-size: small;
      font-weight: bold;
      margin-left: 5vw;
      padding-right: 25vw;
      padding-left: 10vw;
      font-size: 0.8rem;

      @media (max-width: 720px) {
        padding-left: 2rem;
        margin-left: 0;
        margin-top: 5rem;
        margin-bottom: 3rem;
      }

      &_text {
        padding-bottom: 1rem;
      }
    }

  }
}
